import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import LayoutStyles from '../../Components/Layout/Styles/LayoutStyles';
import UserActions from '../../Redux/UsersRedux';
import { checkEmailValidity } from '../../Services/DataHelper';
import Roles from '../../Acl/Roles';
import Routes from '../../Routing/Routes';
import SignUpForm from './SignUpForm';

const useStyles = makeStyles(() => ({
  layout: LayoutStyles.centered600Layout,
  paper: LayoutStyles.centered400Paper,
}));

type Props = {
  leagueId: Number,
  courseId: Number,
  onSubmitClick: Function,
};

const LeagueSignUp = ({ leagueId, courseId, onSubmitClick }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.auth.currentUser);
  const leagueName = t(`${Routes.currentNamespace()}.SEO.SITENAME`);

  const handleSubmit = (
    event,
    firstName,
    lastName,
    phone,
    countryCode,
    phoneError,
    email,
    description,
  ) => {
    if ((!currentUser && !checkEmailValidity(email)) || phoneError) return;

    event.preventDefault();
    const user = {
      phone,
      country_code: countryCode,
      email: email?.toLowerCase(),
      description,
      role: Roles.PRIMARY,
      first_name: firstName,
      last_name: lastName,
    };

    const origin = { league_id: leagueId, origin_type: 'course', origin_id: courseId };
    if (leagueId && courseId) {
      dispatch(UserActions.createUserRequest(user, origin));
    }
    if (onSubmitClick) {
      onSubmitClick(email);
    }
  };

  return (
    <>
      <Grid container className={classes.layout} style={{ marginTop: 0 }}>
        <Paper className={classes.paper}>
          <Typography gutterBottom align="center" variant="h5">
            {t('WELLO.SIGN_UP')}
          </Typography>
          <Typography align="center" variant="subtitle1">
            {t('WELLO.SIGN_UP.SUBTITLE.COURSE.IS_NOT_LOGGED', { leagueName })}
          </Typography>
          <SignUpForm handleSubmit={handleSubmit} withPostCode />
        </Paper>
      </Grid>
    </>
  );
};

export default LeagueSignUp;
