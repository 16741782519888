import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useHistory } from 'react-router';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import Card from '@material-ui/core/Card';
import Dialog from '@material-ui/core/Dialog';
import LinearProgress from '@material-ui/core/LinearProgress';

import { muiTheme } from '../../Themes';
import LayoutStyles from '../../Components/Layout/Styles/LayoutStyles';
import Seo from '../../Routing/Seo';
import Routes from '../../Routing/Routes';
import { useLocation, useRouteMatch } from 'react-router';
import { Button } from '@material-ui/core';
import AccordionUnit from '../../Components/Shared/Courses/AccordionUnit';
import CardCourseDetail from '../../Components/Shared/Courses/CardCourseDetail';
import ConnexionDialog from '../../Components/Shared/ConnexionDialog';
import LeagueSignUp from '../Authentication/LeagueSignUp';
import { getSeoDescription } from '../../Services/DataHelper';
import CoursesActions from '../../Redux/CoursesRedux';
import NoMatch404 from '../NoMatch404';
import NoMatch401 from '../NoMatch401';

import loadable from '@loadable/component';
const ReactHlsPlayer = loadable(() => import('react-hls-player'), { ssr: false });

const useStyles = makeStyles(theme => ({
  headerContainer: {
    backgroundColor: '#fff',
    boxShadow:
      '0px 2px 3px -1px rgba(0, 0, 0, 0.12), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 1px 0px 0px rgba(0, 0, 0, 0.12)',
  },
  headerLayout: {
    ...LayoutStyles.fullWidthLayout,
    width: '100%',
    padding: '20px',
  },
  layout: {
    ...LayoutStyles.fullWidthLayout,
    paddingTop: '40px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
    },
  },
  sideContainer: {
    padding: theme.spacing(3),
    [theme.breakpoints.only('xs')]: {
      padding: 0,
      paddingBottom: theme.spacing(3),
    },
  },
  card: {
    padding: theme.spacing(6),
    [theme.breakpoints.only('xs')]: {
      padding: theme.spacing(3),
    },
  },
  content: {
    marginBottom: theme.spacing(6),
  },
  subtitle: {
    paddingLeft: 2,
  },
  link: {
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    color: 'inherit',
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(-1),
  },
  bold: {
    fontWeight: 600,
    marginBottom: theme.spacing(1),
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  btn: {
    backgroundColor: muiTheme.current.primary.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.current.primary.dark,
    },
  },
  btnContainer: {
    marginTop: theme.spacing(6),
  },
}));

const ShowCourse = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const { course_slug: courseSlug } = match.params;
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const queryParam = new URLSearchParams(location.search);
  const urlParams = {
    goBack: queryParam.get('goBack') || '',
  };
  const isLoggedIn = useSelector(state => state.auth.isLoggedIn);

  const course = useSelector(state => state.courses.course);
  const isFetching = useSelector(state => state.courses.isFetching.getCourse);
  const errors = useSelector(state => state.courses.errors.getCourse);

  const courseFetch =
    !isFetching && (!errors || errors.course_slug !== courseSlug) && course?.slug === courseSlug;

  const hasChapter = course?.units.length > 0 && course?.units[0]?.chapters.length > 0;
  const [openConnexionDialog, setOpenConnexionDialog] = React.useState(false);
  const [openSignUp, setOpenSignUp] = React.useState(false);

  React.useEffect(() => {
    if (courseSlug) {
      dispatch(CoursesActions.getCourseRequest(courseSlug));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseSlug]);

  if (!isFetching && errors?.status === 404 && errors?.course_slug === courseSlug) {
    return <NoMatch404 />;
  }

  if (!isFetching && errors?.status === 401 && errors?.course_slug === courseSlug) {
    return <NoMatch401 />;
  }

  const getNextChapter = () => {
    let nextChapter = null;
    course?.units?.find(
      unit => (nextChapter = unit?.chapters?.find(chapter => !chapter.terminated)),
    );
    return nextChapter || course?.units[0]?.chapters[0];
  };

  const nextChapterId = getNextChapter()?.id;

  const handleClickStartCourse = () => {
    if (isLoggedIn) {
      history.push(`/formations/${courseSlug}/chapitres/${nextChapterId}`);
      window.dataLayer.push({
        event: 'gtm_view_start_mooc',
        title: course?.title,
        slug: course?.slug,
      });
    } else {
      setOpenConnexionDialog(true);
    }
  };

  const handleOpenSignUp = () => {
    setOpenSignUp(true);
    setOpenConnexionDialog(false);
  };

  const handleSubmitSignUpClick = () => {
    setOpenSignUp(false);
    window.dataLayer.push({
      event: 'gtm_create_account',
      origin: 'mooc',
    });
  };

  const renderHeaderSection = () => (
    <Grid item sm={12} xs={12}>
      {urlParams.goBack === 'search' && (
        <a href="/recherche/formations" className={classes.link}>
          <ArrowLeftIcon />
          {t('APP.COURSES.SHOW.BACK')}
        </a>
      )}
      <Typography variant="h2" component="h1" className={classes.bold}>
        {course?.title}
      </Typography>
      <Typography variant="subtitle2" paragraph className={classes.subtitle}>
        {t('APP.COURSES.SHOW.CREATE_AT', { date: moment(course?.created_at).format('LL') })}
        {course?.visibility === 'private' && t('APP.COURSES.VISIBILITY')}
      </Typography>
    </Grid>
  );

  return (
    <>
      {isFetching && <LinearProgress />}
      {courseFetch && (
        <>
          <Helmet>
            {Seo.title(`${course?.title}${t(`${Routes.currentNamespace()}.SEO.SUFFIX`)}`)}
            {Seo.description(getSeoDescription(course?.description))}
            {Seo.image(course?.cover_image_url)}
            {Seo.canonical(course?.creation_league_id, match.url)}
          </Helmet>

          <Grid>
            {/* HEADER SECTION */}
            <Grid container className={classes.headerContainer}>
              <Grid container className={classes.headerLayout}>
                {renderHeaderSection()}
              </Grid>
            </Grid>
            {/* LEFT SECTION */}
            <Grid container className={classes.layout}>
              <Grid item md={8} xs={12} className={classes.sideContainer}>
                <Card className={classes.card}>
                  {course?.teaser_url && (
                    <>
                      <Typography variant="h3" component="h2" className={classes.title}>
                        {t('APP.COURSES.SHOW.TEASER')}
                      </Typography>
                      <ReactHlsPlayer
                        src={course?.teaser_url}
                        autoPlay={false}
                        controls={true}
                        width="100%"
                        height="auto"
                        className={classes.content}
                      />
                    </>
                  )}
                  {course?.description && (
                    <>
                      <Typography variant="h3" component="h2" className={classes.title}>
                        {t('APP.COURSES.SHOW.DESCRIPTION')}
                      </Typography>
                      <Typography
                        className={classes.content}
                        dangerouslySetInnerHTML={{ __html: course?.description }}
                      />
                    </>
                  )}
                  {course?.objectives && (
                    <>
                      <Typography variant="h3" component="h2" className={classes.title}>
                        {t('APP.COURSES.SHOW.GOALS')}
                      </Typography>
                      <Typography
                        className={classes.content}
                        dangerouslySetInnerHTML={{ __html: course?.objectives }}
                      />
                    </>
                  )}
                  <Typography variant="h3" component="h2" className={classes.title}>
                    {t('APP.COURSES.SHOW.CONTENT')}
                  </Typography>
                  {course?.units?.map(unit => (
                    <AccordionUnit
                      key={unit.id}
                      unit={unit}
                      nextChapterId={nextChapterId}
                      isLoggedIn={isLoggedIn}
                    />
                  ))}
                  {hasChapter && (
                    <Grid align="center" className={classes.btnContainer}>
                      <Button
                        variant="contained"
                        className={classes.btn}
                        onClick={handleClickStartCourse}
                      >
                        {t(`APP.COURSES.SHOW.${course?.progression}`)}
                      </Button>
                    </Grid>
                  )}
                </Card>
              </Grid>
              {/* RIGHT SECTION */}
              <Grid item md={4} xs={12} className={classes.sideContainer}>
                <CardCourseDetail
                  course={course}
                  hasChapter={hasChapter}
                  handleClickStartCourse={handleClickStartCourse}
                />
              </Grid>
            </Grid>
          </Grid>
          <Dialog open={openConnexionDialog} onClose={() => setOpenConnexionDialog(false)}>
            <ConnexionDialog
              dialogTitle={t('APP.COURSES.SHOW.DIALOG_TITLE')}
              dialogContent={t('APP.COURSES.SHOW.DIALOG_CONTENT')}
              handleOpenSignUp={handleOpenSignUp}
            />
          </Dialog>
          <Dialog open={openSignUp} onClose={() => setOpenSignUp(false)}>
            <LeagueSignUp
              leagueId={course?.creation_league_id}
              courseId={course?.id}
              onSubmitClick={handleSubmitSignUpClick}
            />
          </Dialog>
        </>
      )}
    </>
  );
};

export default ShowCourse;
