import React from 'react';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import Avatar from '@material-ui/core/Avatar';
import { grey, green } from '@material-ui/core/colors';
import Slider from '@mui/material/Slider';
import Box from '@material-ui/core/Box';

import { getAssetTypeIcon } from '../../../Services/CourseHelper';
import { muiTheme } from '../../../Themes';

const useStyles = makeStyles(theme => ({
  summary: {
    flexDirection: 'row-reverse',
    '&.MuiAccordionSummary-root.Mui-expanded': {
      borderBottom: `1px solid ${grey[300]}`,
    },
  },
  details: {
    flexDirection: 'column',
    padding: `16px !important`,
  },
  chapter: {
    display: 'flex',
    alignItems: 'flex-start',
    margin: 8,
  },
  avatar: {
    backgroundColor: 'white',
    marginRight: 8,
    height: 24,
    width: 24,
  },
  slider: {
    width: '125px !important',
    margin: theme.spacing(0, 3),
    '& .MuiSlider-thumb': { width: 0, height: 0 },
    '& .MuiSlider-track': { border: 0 },
    '& .MuiSlider-rail': { color: grey[600] },
    '&.MuiSlider-root.Mui-disabled': { color: muiTheme.current.primary.main, padding: '8px 0' },
  },
  playCircleIcon: {
    color: muiTheme.current.primary.main,
  },
  chapterTitle: {
    alignSelf: 'center',
  },
}));

type Props = {
  unit: Object,
  nextChapterId: Number,
  isLoggedIn: Boolean,
};

const AccordionUnit = ({ unit, nextChapterId, isLoggedIn }: Props) => {
  const classes = useStyles();
  const history = useHistory();
  const nextChapter = unit?.chapters?.find(chapter => !chapter?.terminated) || unit?.chapters[0];
  const index = unit?.sort_index + 1;
  const hasChapter = unit?.chapters?.length > 0;
  const isCurrentUnit = unit?.chapters?.find(chapter => chapter?.id === nextChapterId);

  return (
    <Accordion defaultExpanded={isCurrentUnit}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="unit-course"
        className={classes.summary}
      >
        <Grid container style={{ justifyContent: 'space-between', flexWrap: 'wrap' }}>
          <Typography variant="h4" component="h3" style={{ marginLeft: 24, alignSelf: 'center' }}>
            {`${index}. ${unit?.title}`}
          </Typography>
          <Box>
            <Slider
              disabled
              aria-label="course-advancement"
              value={unit?.advancement}
              className={classes.slider}
            />
            {hasChapter && isLoggedIn && (
              <PlayCircleIcon
                className={classes.playCircleIcon}
                onClick={() =>
                  history.push(`/formations/${unit.course_slug}/chapitres/${nextChapter.id}`)
                }
              />
            )}
          </Box>
        </Grid>
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        {unit?.chapters.map(chapter => (
          <Grid key={chapter?.id} className={classes.chapter}>
            <Avatar className={classes.avatar}>
              {getAssetTypeIcon(chapter?.asset_type, chapter?.terminated ? green[600] : grey[600])}
            </Avatar>
            <Typography variant="body2" component="h4" className={classes.chapterTitle}>
              {chapter?.title}
            </Typography>
          </Grid>
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

export default AccordionUnit;
