import React from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import CardMedia from '@material-ui/core/CardMedia';
import Chip from '@material-ui/core/Chip';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PeopleIcon from '@mui/icons-material/People';
import { Button } from '@material-ui/core';
import CheckIcon from '@mui/icons-material/Check';
import PauseIcon from '@mui/icons-material/Pause';

import { muiTheme } from '../../../Themes';
import { getCdnUrl } from '../../../Services/ImageResizer';

const useStyles = makeStyles(theme => ({
  cardContent: {
    padding: theme.spacing(3),
  },
  details: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: theme.spacing(2),
    flexWrap: 'wrap',
  },
  icon: {
    marginRight: theme.spacing(0.5),
  },
  marginRight: {
    marginRight: theme.spacing(2),
  },
  btn: {
    backgroundColor: muiTheme.current.primary.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.current.primary.dark,
    },
  },
  progression: {
    alignItems: 'center',
  },
}));

type Props = {
  course: Object,
  hasChapter: Boolean,
  handleClickStartCourse: Function,
};

const CardCourseDetail = ({ course, hasChapter, handleClickStartCourse }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Card>
      <CardMedia
        component="img"
        height="auto"
        image={getCdnUrl(course?.cover_image_url, 350, null)}
        alt={course?.title}
      />
      <CardContent className={classes.cardContent}>
        <Grid className={classes.details}>
          <AccessTimeIcon className={classes.icon} />
          <Typography variant="caption" className={classes.marginRight}>
            {course?.duration}
          </Typography>
          <PeopleIcon className={classes.icon} />
          <Chip label={course?.postable_name} className={classes.marginRight} />
          {course.progression !== 'uninitiated' && (
            <Grid container className={classes.progression}>
              {course.progression === 'terminated' ? (
                <CheckIcon className={classes.icon} />
              ) : (
                <PauseIcon className={classes.icon} />
              )}
              <Typography variant="caption">
                {t(`APP.COURSES.PROGRESSION.${course.progression}`)}
              </Typography>
            </Grid>
          )}
        </Grid>
        {hasChapter && (
          <Button variant="contained" className={classes.btn} onClick={handleClickStartCourse}>
            {t(`APP.COURSES.SHOW.${course.progression}`)}
          </Button>
        )}
      </CardContent>
    </Card>
  );
};

export default CardCourseDetail;
